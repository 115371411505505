.countdown__block--email_form {
  margin-top: 30px;
  width: 100%;
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
}

.countdown__form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.countdown__email-group {
  display: flex;
  position: relative;
  border: 1px solid rgba(255,255,255,0.3);
  border-radius: 0;
  overflow: hidden;
  background: rgba(255,255,255,0.1);
  margin-bottom: 0;
  width: 100%;
}

.countdown__email-group input[type="email"] {
  flex: 1;
  border: none;
  padding: 12px 20px;
  height: 50px;
  background: transparent;
  font-size: 16px;
  color: #fff;
}

.countdown__email-group input[type="email"]::placeholder {
  color: rgba(255,255,255,0.7);
}

.countdown__email-group .input-group-btn {
  flex-shrink: 0;
}

.countdown__email-group .btn {
  height: 50px;
  border-radius: 0;
  padding: 0 30px;
  font-size: 14px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  background: #dfd3b9;
  color: #fff;
  border: none;
  font-family: 'FTRegolaNeue-Semibold', sans-serif;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.countdown__email-group .btn:hover {
  background-color: #d1c5ac;
}

@media only screen and (max-width: 768px) {
  .countdown__block--email_form {
    max-width: 90%;
  }
  
  .countdown__email-group .btn {
    padding: 0 15px;
    font-size: 12px;
  }
}
